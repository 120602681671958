var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.globalShare.addGroupVerificationPopupStatus,
        "custom-class": "joinGroupDialog",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.globalShare,
            "addGroupVerificationPopupStatus",
            $event
          )
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "groupOwnerInformation" }, [
        _c(
          "div",
          { staticClass: "groupOwnerAvatar" },
          [
            _c("Avatar", {
              attrs: {
                path: _vm.informationForJoinGroup.profile_photo,
                international: 0
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "groupOwnerName" }, [
          _vm._v(_vm._s(_vm.informationForJoinGroup.name))
        ])
      ]),
      _c(
        "div",
        { staticClass: "joinGroupTips" },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              resize: "none",
              rows: "5",
              maxlength: "100"
            },
            model: {
              value: _vm.tips,
              callback: function($$v) {
                _vm.tips = $$v
              },
              expression: "tips"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "dialogFooter" }, [
        _c(
          "div",
          {
            staticClass: "submitButton",
            class: _vm.canStartJoinGroup ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.submitFunc.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }