<template>
  <el-dialog
    :visible.sync="$store.state.globalShare.addGroupVerificationPopupStatus"
    custom-class="joinGroupDialog"
    @close="close"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="groupOwnerInformation">
      <div class="groupOwnerAvatar">
        <Avatar
          :path="informationForJoinGroup.profile_photo"
          :international="0"
        ></Avatar>
      </div>
      <div class="groupOwnerName">{{ informationForJoinGroup.name }}</div>
    </div>
    <div class="joinGroupTips">
      <el-input
        type="textarea"
        v-model="tips"
        resize="none"
        rows="5"
        maxlength="100"
      ></el-input>
    </div>
    <div class="dialogFooter">
      <div
        class="submitButton"
        :class="canStartJoinGroup ? 'commomButtonHover' : ''"
        @click.stop="submitFunc"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { joinGroup } from "@/api/newVersion/groupCenter";
import Avatar from "../avatarDisplay";
export default {
  name: "addGroupDialog",
  components: {
    Avatar,
  },
  computed: {
    // 获取加入群组需要的信息
    informationForJoinGroup() {
      return this.$store.state.globalShare.informationForJoinGroup;
    },
    // 判断是否可以提交加群申请
    canStartJoinGroup() {
      return this.globalTrim(this.tips) !== "";
    },
  },
  mounted() {
    this.tips = "";
  },
  data() {
    return {
      // 输入的进群申请
      tips: "",
    };
  },
  methods: {
    // 开始执行提交
    async submitFunc() {
      if (this.canStartJoinGroup && this.informationForJoinGroup.groupId) {
        let params = {
          groupId: this.informationForJoinGroup.groupId,
          userId: this.$store.getters.userInfo.id,
          type: 0,
          tipContent: this.tips || "",
        };
        let result = await joinGroup(params);
        if (result) {
          this.$store.dispatch("commitCloseJoinGroupPopup");
        } else {
          this.$message({
            type: "error",
            message: this.$t("sys_error"),
          });
        }
      }
    },
    // 关闭弹窗前执行操作
    close() {
      this.$store.dispatch("commitCloseJoinGroupPopup");
    },
  },
};
</script>
<style lang="stylus" scoped>
.joinGroupDialog
  .groupOwnerInformation
    width 100%;
    height 50px;
    display flex;
    align-items center;
    justify-content flex-start;
    .groupOwnerAvatar
      width 50px;
      height 50px;
      flex-shrink 0;
      overflow hidden;
      margin-right 10px;
    .groupOwnerName
      flex 1;
      min-width 0;
      height 50px;
      line-height 50px;
      text-align left;
      font-size 14px;
      font-weight bold;
      color #373737;
  .joinGroupTips
    width 100%;
    min-height 0;
    overflow hidden;
    margin-top 16px;
  .dialogFooter
    height 34px;
    width 100%;
    margin-top 25px;
    display flex;
    align-items center;
    justify-content center;
    .submitButton
      width 90px;
      height 100%;
      display flex;
      cursor pointer;
      align-items center;
      justify-content center;
      background #E6E6E6;
      border-radius 4px;
      .iconStyle
        font-size 22px;
        color #FFFFFF;
</style>
<style lang="stylus">
.joinGroupDialog
  width: 500px !important;
  border-radius: 5px !important;
  margin-top 0 !important;
  .el-dialog__body
    padding: 20px 40px 30px 40px !important;
    .el-textarea
      .el-textarea__inner
        border none;
        background #F7F7F7;
        scrollbar-width: none;
        &::-webkit-scrollbar
          width 0px;
</style>
